import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import { Container, Row, Col, Nav } from 'react-bootstrap';
import GlobalContext from 'contexts/Global.context';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

// Components
import Image from 'components/Image/Image';

// SCSS
import './Footer.scss';

const Footer: React.FC = () => {
  const { variantDetails } = useContext(GlobalContext);

  const LOGO =
    variantDetails?.footer_logo_url ||
    `${variantDetails?.assets_url}/footer_logo.svg`;

  const academyURL =
    variantDetails?.academy_url || 'http://beyondphysician.academy';

  const SOCIAL_LINKS = [];
  const FOOTER_LINKS = variantDetails?.footer_links || [
    {
      title: 'Gigs',
      link: '/gigs',
    },
    {
      title: 'Talent',
      link: '/services',
    },
    {
      title: 'Post',
      link: '/post',
    },
    {
      title: 'Academy',
      link: academyURL,
    },
    {
      title: 'About',
      link: variantDetails?.about_page_url || '/about',
    },
    {
      title: 'Contact',
      link: '/support',
    },
    {
      title: 'Privacy Policy',
      link: '/privacy',
    },
    {
      title: 'Terms',
      link: '/terms',
    },
    {
      title: 'Company',
      link: 'http://beyondphysician.org',
    },
  ];
  if (variantDetails?.twitter_link) {
    SOCIAL_LINKS.push({
      name: 'twitter',
      link: variantDetails?.twitter_link,
      icon: faTwitter,
    });
  }
  if (variantDetails?.facebook_link) {
    SOCIAL_LINKS.push({
      name: 'facebook',
      link: variantDetails?.facebook_link,
      icon: faFacebookF,
    });
  }
  if (variantDetails?.instagram_link) {
    SOCIAL_LINKS.push({
      name: 'instagram',
      link: variantDetails?.instagram_link,
      icon: faInstagram,
    });
  }
  if (variantDetails?.linkedin_link) {
    SOCIAL_LINKS.push({
      name: 'linkedin',
      link: variantDetails?.linkedin_link,
      icon: faLinkedin,
    });
  }

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'bp-active-campaign.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container className="footerWrap" fluid>
      <Container className="footer p-0">
        <Row className="align-items-center">
          <Col xl={1} lg={1} md={12} sm={12}>
            <Image
              className="footerLogo"
              src={LOGO}
              title={variantDetails?.site_short_title}
            />
          </Col>
          <Col xl={5} lg={5} md={12} sm={12}>
            <Nav
              className="flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column"
              activeKey="/home"
            >
              {FOOTER_LINKS.map(({ link, title }) =>
                link?.includes('http') ? (
                  <a
                    key={title}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title}
                  </a>
                ) : (
                  <Nav.Item key={title}>
                    <Nav.Link to={link} as={Link} eventKey={title}>
                      {title}
                    </Nav.Link>
                  </Nav.Item>
                ),
              )}
            </Nav>
          </Col>
          <Col xl={3} lg={3} md={12} sm={12} className="p-0">
            <Nav className="p-0 social-menu flex-row justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center">
              {SOCIAL_LINKS.map((socialLink) => (
                <a
                  key={socialLink.name}
                  href={socialLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={socialLink.icon} />
                </a>
              ))}
            </Nav>
          </Col>
          <Col
            className="text-xl-right text-lg-right text-md-center text-sm-center text-center copyrightOuter"
            xl={3}
            lg={3}
            md={12}
            sm={12}
          >
            <div className="copyright">&copy; {variantDetails?.copyright}</div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Footer);
