import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, Alert } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Page Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import FileUpload from 'components/FileUpload/FileUpload';
import TextArea from 'components/Form/TextArea/TextArea';
import Loader from 'components/Loader/Loader';
import Checkbox from 'components/Form/Checkbox/Checkbox';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  //faFile,
  faFileWord,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

// Context
import GlobalContext from 'contexts/Global.context';

// Interfaces
import { Job } from 'interfaces/Job.types';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// Constants
import {
  APP_CONSTANTS,
  JOB_TYPE,
  ROUTES,
  ACADEMY_URL,
} from 'constants/Constants';
import { APPLY_DEFAULTS, APPLY_RULES } from './constants';

// Service
import {
  applyForJobService,
  fetchJobDetailsService,
} from 'services/JobService';
import { getUploadURLService } from 'services/CommonService';

import History from 'utils/History';

// SVGs
import applicationSent from 'assets/svgs/Careers/applicationsent.svg';

// SCSS
import styles from './Apply.module.scss';

// SVGs
import closeIcon from 'assets/svgs/close-icon.svg';
import { fetchApplicantProfileService } from 'services/UserService';
import Select from 'components/Form/Select/Select';

const Apply = () => {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState<Job | null>(null);
  const {
    userDetails: { first_name, last_name, user_name, photo, email, slug },
    variantDetails,
  } = useContext(GlobalContext);

  const [showApplicationReceived, setShowApplicationReceived] =
    useState<boolean>(false);
  const [attachments, setAttachments] = useState<File[] | null>(null);
  const [offer, setOffer] = useState<any>(APPLY_DEFAULTS);
  const [formErrors, setFormErrors] = useState<any>({});

  // API related state
  const [loading, setLoading] = useState<boolean>(false);
  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [userProfile, setUserProfile] = useState<any>({});

  const academyURL =
    variantDetails?.academy_url ||
    ACADEMY_URL ||
    'http://beyondphysician.academy';

  const fetchJobDetails = useCallback(
    async (profile) => {
      setLoading(true);
      const { jobDetails, error } = await fetchJobDetailsService(id);
      if (jobDetails) {
        setJobDetails(jobDetails);
        setOffer({
          ...APPLY_DEFAULTS,
          title: `Offer: ${jobDetails.title}`,
          service_credential:
            profile?.certificates?.find(
              (cert) => cert.category === jobDetails.category,
            )?.credential_title || null,
          note: 'I am interested in applying to your Gig',
          flat_fee: jobDetails.min_pay
            ? (
                Number(jobDetails.min_pay) *
                ((100 - APP_CONSTANTS.platformFee) / 100)
              ).toFixed(2)
            : 0,
        });
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    },
    [id],
  );

  const fetchUserDetails = useCallback(async () => {
    setLoading(true);
    const { profile, error } = await await fetchApplicantProfileService(slug);
    if (profile) {
      setUserProfile(profile);
      fetchJobDetails(profile);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [slug, fetchJobDetails]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  }, []);

  const handleCloseAppSubModal = () => {
    setShowApplicationReceived(false);
    History.push(
      `${
        jobDetails.type === JOB_TYPE.opportunity
          ? ROUTES.OPPORTUNITY
          : ROUTES.CAREER
      }/${id}`,
    );
  };

  const getFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };

  const uploadFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileInfo = {
        name: file.name,
        type: file.type,
      };
      getUploadURLService(fileInfo)
        .then(({ signedRequest, url, error }) => {
          if (signedRequest && url) {
            fetch(signedRequest, {
              method: 'PUT',
              body: file,
            })
              .then((uploadResponse) => {
                if (uploadResponse?.ok) {
                  resolve(url);
                } else {
                  reject('Something went wrong.');
                }
              })
              .catch((error) => {
                reject(error.message);
              });
          } else {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const uploadAttachments = async () => {
    if (attachments?.length) {
      try {
        const uploadResponseArray = await Promise.all(
          attachments.map((file) => uploadFile(file)),
        );
        return { urls: uploadResponseArray };
      } catch (error) {
        setError(`An error occurred while uploading resume: ${error}`);
        return { error };
      }
    }
  };

  const callAnalytics = () => {
    const { type, category, location, title, pay } = jobDetails;
    window.analytics.track(
      type === JOB_TYPE.career ? 'Apply for Job' : 'Applied for opportunity',
      {
        title,
        location,
        category: category.split(/(,(?=\S)|:)/)[0],
        pay,
        type,
        appliedBy: email,
      },
    );
  };

  const validateInput = (name, value) => {
    if (
      name === 'service_credential' &&
      !value &&
      jobDetails?.experience?.includes('Required')
    ) {
      return `${APPLY_RULES[name].label} is required`;
    } else if (APPLY_RULES[name]) {
      if (!value && value !== 0 && APPLY_RULES[name].required) {
        if (name === 'flat_fee') {
          return 'Either enter the offer amount or make it free.';
        }
        return `${APPLY_RULES[name].label} is required`;
      } else if (
        value &&
        APPLY_RULES[name].min &&
        value.length < APPLY_RULES[name].min
      ) {
        return `${APPLY_RULES[name].label} should contain at least ${APPLY_RULES[name].min} chars`;
      } else if (
        value &&
        APPLY_RULES[name].max &&
        value.length > APPLY_RULES[name].max
      ) {
        return `${APPLY_RULES[name].label} should contain at least ${APPLY_RULES[name].max} chars`;
      }
    }
    return '';
  };

  const handleChange = (
    { target: { name, value, checked } }: any,
    isCheck = false,
  ) => {
    if (name === 'flat_fee' && isCheck) {
      if (checked) {
        setOffer({ ...offer, flat_fee: 0 });
      } else {
        setOffer({ ...offer, flat_fee: '' });
      }
    } else {
      setOffer({ ...offer, [name]: value });
    }
    const inputError = validateInput(name, value);
    setFormErrors({ ...formErrors, [name]: inputError });
  };

  const validateBody = () => {
    const bodyErrors = {};
    Object.keys(APPLY_RULES).forEach((key: string) => {
      let inputError = validateInput(key, offer[key]);
      if (inputError) {
        bodyErrors[key] = inputError;
      }
    });
    setFormErrors({ ...formErrors, ...bodyErrors });
    return Object.keys(bodyErrors).length === 0;
  };

  const applyForJob = async (urls?: string[]) => {
    setLoading(true);
    const apiBody = {
      attachments: urls || [],
      job: id,
      ...offer,
      amount: parseFloat(offer.flat_fee),
      hourly_fee: parseFloat(offer.hourly_fee || 0),
      schedule: offer.schedule.filter(({ date, delivery }) => {
        return !!date && !!delivery;
      }),
    };
    delete apiBody.flat_fee;
    const { applied, error } = await applyForJobService(apiBody);
    if (applied) {
      callAnalytics();
      setIsApplied(true);
      setShowApplicationReceived(true);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  };

  const triggerApplyForJob = async () => {
    setError('');
    if (!validateBody()) {
      return;
    }
    if (attachments?.length) {
      setLoading(true);
      const { urls, error }: any = await uploadAttachments();
      if (urls) {
        applyForJob(urls);
      } else if (error) {
        setError(error);
      }
    } else {
      applyForJob();
    }
  };

  const removeAttachment = (index: number) => {
    setAttachments([
      ...attachments.slice(0, index),
      ...attachments.slice(index + 1),
    ]);
  };

  const handleScheduleChange = (event: any, index: number) => {
    const { value, name } = event.target;
    const schedules = JSON.parse(JSON.stringify(offer.schedule));
    schedules[index][name] = value;
    setOffer({
      ...offer,
      schedule: schedules,
    });
  };

  const addSchedule = () => {
    setOffer({
      ...offer,
      schedule: [
        ...offer.schedule,
        { date: '', delivery: '', is_completed: false },
      ],
    });
  };

  return (
    <div className={styles.offerWrapper}>
      <Container>
        {!jobDetails && loading && <Loader />}
        {jobDetails && (
          <Row>
            <Col>
              <div className={styles.pageHeading}>Submit Offer</div>
              {/* <div className={styles.modalSubTitle}>Apply For</div> */}
              <div className={styles.jobTitle}>{jobDetails.title}</div>
              <Row>
                <Col className={styles.userAvatar} xs="auto">
                  <div className={styles.userDetailOuter}>
                    <div className={styles.userAvatar}>
                      <img
                        src={
                          photo ||
                          'https://mic-react.s3.amazonaws.com/place_70x70.png'
                        }
                        alt={user_name}
                      />
                    </div>
                    <div className={styles.userName}>
                      {`${first_name} ${last_name}`}
                    </div>
                  </div>
                </Col>
                <Col xs="auto">
                  {/* <div className={styles.modalDataLinks}>
                  {resume && (
                    <>
                      <FontAwesomeIcon icon={faFile} />
                      <a
                        href={resume}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Resume
                      </a>
                    </>
                  )}
                </div> */}
                </Col>
              </Row>
              <Row
                className={`${styles.modalContainer} ${styles.modalInputWrap}`}
              >
                <Col>
                  <Row>
                    <Col>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Offer Title
                          <span className={styles.requiredField}> *</span>
                          <div
                            className={`${styles.requiredFieldsNote} text-right`}
                          >
                            <span>* </span>= Required Fields
                          </div>
                        </div>
                        <Input
                          type="text"
                          onChange={handleChange}
                          value={offer.title}
                          name="title"
                          className={formErrors.title ? styles.errorInput : ''}
                        />
                        {formErrors.title && (
                          <div className={styles.error}>{formErrors.title}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Offer Description
                        </div>
                        <TextArea
                          placeholder="i.e. Experience, Skills , Details -- do not put your offer/counter offer in the message, but rather in the designated field below."
                          onChange={handleChange}
                          value={offer.note}
                          name="note"
                          className={formErrors.title ? styles.errorInput : ''}
                        />
                        {formErrors.note && (
                          <div className={styles.error}>{formErrors.note}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Total Offer Amount
                          <span className={styles.requiredField}> *</span>
                        </div>
                        <Input
                          type="text"
                          placeholder="$0"
                          onChange={handleChange}
                          value={offer.flat_fee}
                          name="flat_fee"
                          className={
                            formErrors.flat_fee ? styles.errorInput : ''
                          }
                        />
                        {formErrors.flat_fee && (
                          <div className={styles.error}>
                            {formErrors.flat_fee}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={2} md={2}>
                      <div className={styles.authInput}>
                        <div
                          className={styles.authLabel}
                          style={{ marginTop: '50px' }}
                        ></div>
                        <Checkbox
                          id="free"
                          name="flat_fee"
                          onChange={(e) => handleChange(e, true)}
                          isChecked={offer.flat_fee === 0}
                          label="Free"
                          value="Free"
                        />
                      </div>
                    </Col>
                    <Col xs={4} md={3}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Additional Hourly
                        </div>
                        <Input
                          type="text"
                          placeholder="$0/hr"
                          onChange={handleChange}
                          value={offer.hourly_fee}
                          name="hourly_fee"
                          className={
                            formErrors.hourly_fee ? styles.errorInput : ''
                          }
                        />
                        {formErrors.hourly_fee && (
                          <div className={styles.error}>
                            {formErrors.hourly_fee}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <div className={styles.conterOffers}>
                        <div className={styles.heading}>
                          Expect Counter Offers
                        </div>
                        <div className={styles.desc}>
                          Counter offers are to be expected. The person
                          requesting the job will have a chance to counter your
                          flat fee.
                        </div>
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className={styles.conterOffers}>
                        <div className={styles.heading}>Note</div>
                        <div className={styles.desc}>
                          For Gigs only to let buyers know your fee if the scope
                          is increased.
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.authLabel}>
                        Milestone Schedule/Breakdown
                      </div>
                    </Col>
                  </Row>
                  {offer.schedule &&
                    offer.schedule.map((item, index) => (
                      <Row key={index}>
                        <Col md={3} sm={4}>
                          <div className={styles.authInput}>
                            <Input
                              type="date"
                              onChange={(e) => handleScheduleChange(e, index)}
                              value={item.date}
                              name="date"
                            />
                          </div>
                        </Col>
                        <Col md={9} sm={8}>
                          <div className={styles.authInput}>
                            <Input
                              onChange={(e) => handleScheduleChange(e, index)}
                              name="delivery"
                              type="text"
                              value={item.delivery}
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}
                  <Row>
                    <Col className={styles.iconButton}>
                      <Button
                        onClick={addSchedule}
                        type="outline-gray"
                        size="lg"
                        label="Add More"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Add Your Service Credential
                          {jobDetails?.experience?.includes('Required') && (
                            <span className={styles.requiredField}> *</span>
                          )}
                          <div
                            className={styles.authLabelInfo}
                            style={{ marginTop: '0px' }}
                          >
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.skillLink}
                              href={`${academyURL}/certifications?category=${jobDetails?.category}`}
                            >
                              Click here
                            </a>{' '}
                            to directly access the assessment quiz to earn
                            credential.
                          </div>
                        </div>
                        <Select
                          id="add_cred_service"
                          value={offer.service_credential}
                          selectItems={
                            userProfile?.certificates?.map((cred) => ({
                              label: cred.credential_title,
                              value: cred.credential_title,
                            })) || []
                          }
                          onChange={handleChange}
                          name="service_credential"
                        />
                        {formErrors.service_credential && (
                          <div className={styles.error}>
                            {formErrors.service_credential}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.authLabel}>
                        Upload Files/Documents
                      </div>
                      <div className={styles.authLabelInfo}>
                        Upload your resume, any credentials, or documents that
                        help support your offer.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12}>
                      {attachments?.map((file, index) => (
                        <div
                          className={styles.attachmentsListing}
                          key={file.name}
                        >
                          <div className={styles.attachmentIcon}>
                            <FontAwesomeIcon
                              icon={
                                getFileExtension(file?.name) === 'pdf'
                                  ? faFilePdf
                                  : faFileWord
                              }
                            />
                          </div>
                          <div className={styles.resultData}>
                            <div className={styles.resultName}>{file.name}</div>
                          </div>
                          <div
                            className={styles.attachmentRemove}
                            onClick={() => removeAttachment(index)}
                          >
                            Remove
                          </div>
                        </div>
                      ))}
                      <FileUpload
                        buttonStyles="gray w-50"
                        label={attachments?.length > 0 ? 'Add More' : 'Upload'}
                        onFileChange={(file) =>
                          setAttachments([...(attachments || []), file])
                        }
                      />
                      <div className={styles.fileInfo}>
                        <div>5mb max</div>
                        <div>500px wide</div>
                        <div>500px tall</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Any Questions?</div>
                        <TextArea
                          className={styles.shortText}
                          placeholder=""
                          value={offer.question}
                          name="question"
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                {/* <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                <div className={styles.modalInputLabel}>
                  Application Message
                </div>
              </Col>
              <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                <div className={styles.modalInput}>
                </div>
              </Col> */}
              </Row>
              <div className={styles.information}>
                We have prefilled the application based on the Gig listing.
                Click here to apply as is, or feel free to amend the title,
                description, pricing, milestones, or upload any relevant
                documents to customize your application. After clicking this
                button to submit your application, you will receive a
                confirmation email.
              </div>
              <Row
                className={`my-4 ${styles.modalContainer} ${styles.modalInputWrap}`}
              >
                <Col>
                  <Button
                    type="outline-gray w-100 p-3 bold"
                    size="lg"
                    label={loading ? 'loading...' : 'Submit My Offer'}
                    disabled={isApplied || loading}
                    onClick={triggerApplyForJob}
                  />
                </Col>
              </Row>
              {isApplied && (
                <Alert variant="success">Application sent successfully!</Alert>
              )}
              {error && <Alert variant="danger">{error}</Alert>}
              Offers are submitted to the Gig owner directly for review.{' '}
              {COPY_OVERRIDES?.apply?.company || 'Music Career Map'} cannot
              assist with any communication between you and the Gig owner until
              you are accepted for the Gig.
              {/* Application submission Modal */}
              <Modal
                show={showApplicationReceived}
                onHide={loading ? () => {} : handleCloseAppSubModal}
                className={styles.applicationSentModalWrap}
                centered
              >
                <div
                  className={styles.closeBtn}
                  onClick={handleCloseAppSubModal}
                >
                  Close{' '}
                  <span>
                    <SVG
                      baseURL="/"
                      className={styles.closeIcon}
                      description="close"
                      loader={<span>Loading...</span>}
                      src={closeIcon}
                    />
                  </span>
                </div>
                <Modal.Body className={styles.applicationModalBody}>
                  <div className={styles.applicationReceived}>
                    <div className={styles.planeIconContainer}>
                      <SVG
                        baseURL="/"
                        cacheRequests={true}
                        className={styles.planeIcon}
                        description="Application Sent"
                        loader={<span>Loading...</span>}
                        src={applicationSent}
                      />
                    </div>
                    <div className={styles.bigText}>
                      Thank you for applying.
                    </div>
                  </div>
                  {/* <Row>
                    <Col className="mt-3">
                      <Button
                        type="outline-gray w-100 p-3"
                        size="sm"
                        label="Close"
                        onClick={handleCloseAppSubModal}
                      />
                    </Col>
                  </Row> */}
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default React.memo(Apply);
